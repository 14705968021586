import React, { useState, useEffect } from 'react'

import Form from '../Form/Form';
import FindResult from '../FindResult/FindResult';
import Calculate from '../Calculate/Calculate';
import Complete from '../Complete/Complete';
import Fail from '../Fail/Fail';
import Loader from '../Loader/Loader';

import service from '../../services/FormService'
import cashDesk, { receiptRequest, serviceProvider, serviceInfo } from '../../services/CashService'
import paymentService from '../../services/PaymentService'
import processingService from '../../services/ProcessingService'

import config from '../../config'

const TITLE = 'Услуга'

export default function Service(props) {

    const servId = props.match.params.servId ?? 0;
    const amounts = {
        feeOutPercent: 0,
        feeOutMin: 0,
        feeOutMax: 0,
        feeOutFixed: 0,
        minSum: 0,
        maxSum: 15000,
        amount: 0,
        amountFee: 0,
        total: 0
    };

    const [currentView, setCurrenView] = useState(serviceViews.Form);
    const [serviceDetails, setServiceDetails] = useState({ name: TITLE, providerInfo: '' });
    const [serviceAmounts, setServiceAmounts] = useState(amounts);
    const [formFieldList, setFormFieldList] = useState();
    const [completeInfo, setCompleteInfo] = useState();
    const [findResultInfo, setFindResultInfo] = useState();
    const [failInfo, setFailInfo] = useState({ message: '' });
    const [isLoading, setIsLoading] = useState(false);
    const [isWaiting, setIsWaiting] = useState(false);

    const toCheck = () => {
        const indxId = formFieldList.findIndex(x => x.Name === "id1");
        const accId = indxId >= 0 ? formFieldList[indxId].Value : null;

        setIsWaiting(true);
        processingService
            .checkAvailable(servId, accId)
            .then(
                (data) => {
                    setIsWaiting(false);
                    console.log(data);
                    setServiceAmounts({
                        ...serviceAmounts, amount: data.balance > 0 ? 0 : -data.balance
                    });

                    setFindResultInfo({
                        fieldList: formFieldList,
                        addInfo: data.addInfo
                    })
                    setCurrenView(serviceViews.FindResult);
                },
                (err) => {
                    setIsWaiting(false);
                    console.error(`processingService.checkAvailable: error:${err}`);
                    setFailInfo({ message: 'Ошибка проверки номера/лицевого счета. Проверьте соединение с интернетом. При повторном возникновении, обратитесь в службу технической поддержки.' });
                    setCurrenView(serviceViews.Fail);
                }
            )

    }

    const toCalc = () => {
        setCurrenView(serviceViews.Calculate);
    }

    const buildProviderInfoString = (serv) => {
        let info = serv.providerName == null ? '' : serv.providerName;
        info += serv.providerInn == null ? '' : ((info ? ',' : '') + ' ИНН: ' + serv.providerInn);
        info += serv.providerSupport == null ? '' : ((info ? ',' : '') + ' тел.: ' + serv.providerSupport);

        return info;
    }

    const onPrintCheck = (e) => {
        const btn = e.target;

        ///
        const serv = {
            ...serviceDetails, ...serviceAmounts
        };

        const item = {
            ...serviceInfo,
            ...{
                name: serv.name,
                amount: serv.amount,
                descript: findResultInfo.addInfo
            }
        }

        let indxId = formFieldList.findIndex(x => x.Name === "id1");
        const accId = indxId >= 0 ? formFieldList[indxId].Value : null;
        indxId = formFieldList.findIndex(x => x.Name === "phone");
        const clientPhoneNumber = indxId >= 0 ? formFieldList[indxId].Value : accId;

        const provider = {
            ...serviceProvider,
            ...{
                name: serv.providerName,
                phone: serv.providerSupport,
                inn: serv.providerInn,
                accountNumber: accId,
                serviceList: [item]
            }
        }

        const payment = {
            ProviderId: "cmp",
            ServiceId: servId,
            AccountId: accId,
            phoneNumber: clientPhoneNumber,
            Attributes: JSON.stringify(formFieldList),
            Amount: serviceAmounts.amount,
            FeeIn: 0,
            FeeOut: serviceAmounts.amountFee
        }

        const receipt = {
            ...receiptRequest,
            ...{
                agentPhone: config.agentPhone,
                feeOut: serv.amountFee,
                phoneEmail: '',
                providerList: [provider]
            }
        }
        ///
        const addPayment = () => {
            btn.disabled = true;

            setIsWaiting(true);
            paymentService
                .add(payment)
                .then(
                    (data) => {
                        console.log(data);
                        setIsWaiting(false);
                        const paymentUid = data.uid
                        printReceipt(paymentUid);
                    },
                    (err) => {
                        console.error(`paymentService.add: error:${err}`);
                        setIsWaiting(false);
                        btn.disabled = false;
                        setFailInfo({ message: 'Ошибка добавления платежа в базу данных. Проверьте соединение с интернетом. При повторном возникновении, обратитесь в службу технической поддержки.' });
                        setCurrenView(serviceViews.Fail);
                    }
                )
        }

        const printReceipt = (paymentUid) => {
            cashDesk
                .printReceipt(receipt)
                .then(
                    (data) => {
                        if ((data.errorCode || 0) !== 0) {
                            console.error(`service.printReceipt: error:${data.errorCode}, message:${data.errorMessage}`);
                            removePayment(paymentUid);
                        } else {
                            console.log(data);
                            updateFiscalInfo(paymentUid, data.receiptNumber, data.receiptDate);
                        }
                    },
                    ({ code, message }) => {
                        console.error(`service.printReceipt: error:${code}, message:${message}`);
                        removePayment(paymentUid);
                    })
        }

        const updateFiscalInfo = (uid, number, date) => {
            const fiscal = {
                paymentUid: uid,
                number: number,
                date: date
            };

            paymentService
                .updateFiscalInfo(fiscal)
                .then(
                    (data) => {
                        console.log(data);
                        btn.disabled = false;
                        setCompleteInfo({ ...payment, ...fiscal });
                        setCurrenView(serviceViews.Complete);
                    },
                    (err) => {
                        console.error(`paymentService.updateFiscalInfo: error:${err}`);
                        btn.disabled = false;
                        setFailInfo({ message: 'Ошибка внесения информации о чеке в базу данных. Обратитесь в службу технической поддержки. Будьте готовы назвать реквизиты чека.' });
                        setCurrenView(serviceViews.Fail);
                    }
                )
        }

        const removePayment = (uid) => {
            paymentService
                .remove(uid)
                .then(
                    (data) => {
                        console.log(data);
                        btn.disabled = false;
                        setFailInfo({ message: `Не удалось распечатать чек. Проверьте подключение кассового аппарата. При повторном возникновении, обратитесь в службу технической поддержки.` });
                        setCurrenView(serviceViews.Fail);
                    },
                    (err) => {
                        console.error(`paymentService.remove: error:${err}`);
                        btn.disabled = false;
                        setFailInfo({ message: 'Не удалось распечатать чек. Удалите платеж из журнала платежей и проверьте подключение кассового аппарата. При повторном возникновении, обратитесь в службу технической поддержки.' });
                        setCurrenView(serviceViews.Fail);
                    }
                )
        }
        ///

        addPayment();
    }

    useEffect(() => {
        setIsLoading(true);
        service
            .findByServiceId(servId)
            .then(
                ({ success, data }) => {
                    setIsLoading(false);
                    if (success) {
                        const { feeDetails } = data.formService;
                        setServiceAmounts({
                            feeOutPercent: feeDetails.percent,
                            feeOutMin: feeDetails.minAmount,
                            feeOutMax: feeDetails.maxAmount,
                            feeOutFixed: feeDetails.fixed,
                            minSum: data.formService.minSum / 100,
                            maxSum: data.formService.maxSum / 100,
                            amount: 0,
                            amountFee: 0,
                            total: 0
                        });
                        setServiceDetails({
                            providerInfo: buildProviderInfoString(data.formService), ...data.formService
                        });
                        setFormFieldList(JSON.parse(data.formFieldList));
                    }
                },
                ({ error, message }) => {
                    setIsLoading(false);
                    console.error(`service.findByServiceId: error:${error}, message:${message}`);
                })
    }, [servId]);

    return (
        <React.Fragment>
            <h3>{serviceDetails.name}</h3>
            <h5 className="lead">{serviceDetails.providerInfo}</h5>
            <hr className="my-4" />
            {isLoading
                ? <Loader />
                : currentView === serviceViews.Form
                    ? <Form fieldList={formFieldList} next={toCheck} isWaiting={isWaiting} />
                    : currentView === serviceViews.FindResult
                        ? <FindResult serviceInfo={findResultInfo} prev={() => { setCurrenView(serviceViews.Form) }} next={toCalc} isWaiting={isWaiting} />
                        : currentView === serviceViews.Calculate
                            ? <Calculate amounts={serviceAmounts} prev={() => { setCurrenView(serviceViews.FindResult) }} next={onPrintCheck} isWaiting={isWaiting} />
                            : currentView === serviceViews.Complete
                                ? <Complete summary={completeInfo} />
                                : <Fail error={failInfo} />
            }
        </React.Fragment >
    )
}

const serviceViews = Object.freeze({ Form: 1, FindResult: 2, Calculate: 3, Complete: 4, Fail: 5 })
