import request from 'superagent'
import authService from '../components/api-authorization/AuthorizeService'
import { ApplicationPaths } from '../components/api-authorization/ApiAuthorizationConstants'

import {
    each,
    keys,
    some,
    isObject
} from 'underscore'

import ServerError from '../lib/errors/ServerError'
import WebError from '../lib/errors/WebError'

import config from '../config'

const notImplementedTemplates = [
    '/profile',
    // '/catalog'
]

// срабатывает в случае успешной отработки сервера
function onSuccess(response) {
    let parsed = {}

    // парсим JSON-строку ответа сервера вручную
    try {
        parsed = JSON.parse(response.text)
    } catch (e) {
        throw new WebError({
            status: 'Response parse error',
            body: response.body
        })
    }

    const body = parsed.body
    const status = parsed.statusCode

    // отсечём лишние данные из ответа сервера
    if ((status === 200 || status === 201) && body.success !== false) {
        return body
    }

    throw new ServerError({ status, ...body.error })
}

// срабатывает в случае сбоя на сервере (сервер мёртв, не отвечает и пр.), 
// а не просто ошибки в серверной бизнес-логике.
function onFailure(response) {
    const {
        status,
        message,
        body
    } = response

    // Ошибка авторизации
    if (status === 401) {
        authService.userManager.removeUser();
        authService.updateState(undefined);

        window.location.href = `${ApplicationPaths.Login}`
    }

    throw new ServerError({
        body,
        status,
        code: 'internal.server.error',
        message
    })
}

export default class BaseService {
    async getAuth() {

        const token = await authService.getAccessToken();
        if (token)
            return { 'Authorization': `Bearer ${token}` };

        await authService.userManager.removeUser();
        authService.updateState(undefined);
        window.location.href = `${ApplicationPaths.Login}`;

        return {};
    }

    request(opts) {

        opts = {
            method: 'GET',
            url: null,
            uri: null,
            body: null,
            type: 'form',
            params: null,
            callback: null,
            ...opts
        }

        const { remote } = config

        // проверяем, реализован ли на сервере данный API
        const isNotImplemented = some(notImplementedTemplates, t => {
            return opts.uri.includes(t)
        })

        if (!remote.isEnabled || isNotImplemented) {
            throw new WebError({
                body: '',
                status: 404,
                code: 'internal.web.error',
                message: 'API not implemented'
            })
        }

        else {
            const { type, method } = opts
            const url = `${opts.url ?? remote.url}${opts.uri}`;

            if (method === 'GET') {
                let rq = request.get(url)
                    .query(opts.params)
                    .timeout({ response: config.responseTimeout })

                const { headers } = opts
                if (headers) {
                    each(keys(headers), key => {
                        rq.set(key, headers[key])
                    })
                }

                return rq.then(onSuccess, onFailure)
            }

            if (method === 'POST' || method === 'PUT') {
                let rq = request(method, url).type(type)
                    .timeout({ response: config.responseTimeout })

                const { headers } = opts
                if (headers) {
                    each(keys(headers), key => {
                        rq.set(key, headers[key])
                    })
                }

                if (type === 'multipart/form-data') {
                    each(opts.body, (v, k) => {
                        if (v instanceof File) {
                            rq = rq.attach(k, v)
                        }

                        else {
                            if (isObject(v)) {
                                v = JSON.stringify(v)
                            }
                            rq = rq.field(k, v)
                        }
                    })
                } else {
                    rq = rq.send(JSON.stringify(opts.body))
                }

                return rq.then(onSuccess, onFailure)
            }

            if (method === 'DELETE') {
                let rq = request(method, url).type(type)
                    .timeout({ response: config.responseTimeout })

                const { headers } = opts
                if (headers) {
                    each(keys(headers), key => {
                        rq.set(key, headers[key])
                    })
                }

                rq = rq.send(JSON.stringify(opts.body));

                return rq.then(onSuccess, onFailure)
                //return request
                //    .type(type)
                //    .del(url)
                //    .timeout({ response: config.responseTimeout })
                //    .then(onSuccess, onFailure)
            }
        }
    }
}
