import request from 'superagent'

import ServerError from '../lib/errors/ServerError'
import userService from './UserService'

import config from '../config'

export const receiptRequest = {
    isCheckReturn: false,
    agentPhone: '',
    agentAccountNumber: '',
    feeOut: 0.0,
    cashier: '',
    isCashless: false,
    phoneEmail: '',
    providerList: []
}

export const serviceProvider = {
    name: '',
    phone: '',
    inn: '',
    accountNumber: '',
    serviceList: []
}

export const serviceInfo = {
    name: '',
    amount: 0.0,
    descript: ''
}

export class CashService {

    async printReceipt(receipt) {

        const userInfo = await userService.getCurrentUser();
        const cashier = userInfo && userInfo.data && userInfo.data.fullName;

        receipt = {
            ...receipt,
            cashier
        };

        const { cash } = config;

        const onSuccess = (response) => {
            return response.body;
        }

        const onFailure = (response) => {
            const {
                status,
                message,
                body
            } = response

            throw new ServerError({
                body,
                status,
                code: 'internal.server.error',
                message
            })
        }

        return request.post(cash.url + "/receipt")
            .type('json')
            .timeout({ response: cash.responseTimeout })
            .send(receipt ? JSON.stringify(receipt) : '')
            .then(onSuccess)
            .catch(onFailure)
    }
}

export default new CashService()