import BaseService from './BaseService'

export class FormService extends BaseService {
    async find({ filter }) {
        return super.request({
            uri: '/form',
            params: { ...filter },
            headers: await super.getAuth()
        })
    }

    async findByServiceId(ServiceId) {
        return super.request({
            uri: `/form` + (ServiceId ? `/${ServiceId}` : ''),
            headers: await super.getAuth()
        })
    }
}

export default new FormService()