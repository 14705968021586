import BaseService from './BaseService'

export class UserService extends BaseService {

    async getCurrentUser() {
        return super.request({
            uri: '/user',
            headers: await super.getAuth()
        })
    }
}

export default new UserService()