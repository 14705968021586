import React from 'react'
import { Button } from 'reactstrap';
import { useHistory } from "react-router-dom";

export default function Fail(props) {
    const { message } = props.error;

    let history = useHistory();

    const onClickCatalog = () => {
        history.push("/");
    }

    return (
        <div className="jumbotron">
            <h1 className="display-4 text-danger">При проведении платежа возникла ошибка</h1>
            <p className="lead text-danger">{message}</p>
            <hr className="my-4" />
            <div className="d-flex mb-4" >
                <a href={"mailto:support@platilegko.online?subject=Ошибка проведения платежа&body=" + message}>Написать в службу поддержки</a>
                <Button color="primary" className="d-flex ml-auto" onClick={onClickCatalog}>Вернуться в каталог</Button>
            </div>
        </div >
    )
}
