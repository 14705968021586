export default {
    responseTimeout: 30000,

    remote: {
        isEnabled: true, // флаг переключения между фиктивным и реальным сервером
        url: 'api' // URL реального сервера
        //url: 'https://localhost:44376/api' // URL реального сервера
    },

    cash: {
        url: 'http://localhost:5000/api',
        responseTimeout: 60000,
        agentPhone: '66-25-45'
    }
}