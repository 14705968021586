import BaseService from './BaseService'

export class CashBookService extends BaseService {

    async find(filter) {
        return super.request({
            uri: '/cashbook',
            params: { ...filter },
            headers: await super.getAuth()
        })
    }

    async services() {
        return super.request({
            uri: '/services',
            headers: await super.getAuth()
        })
    }

    async cashiers() {
        return super.request({
            uri: '/cashiers',
            headers: await super.getAuth()
        })
    }
}

export default new CashBookService()