import React, { Component } from 'react'

import cn from 'classnames'
import PropTypes from 'prop-types'
import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator';

//import './Table.scss'

const NO_DATA_TEXT = 'Данных нет'

export default class Table extends Component {

    static propTypes = {
        columns: PropTypes.arrayOf(PropTypes.object), // дескрипторы столбцов таблицы
        data: PropTypes.arrayOf(PropTypes.object), // данные таблицы
        keyField: PropTypes.string, // имя уникального столбца
        noDataText: PropTypes.string,

        rowEvents: PropTypes.object, // дескрипторы событий строки

        hasHover: PropTypes.bool,
        hasOptions: PropTypes.bool,
        hasBorders: PropTypes.bool,

        isStriped: PropTypes.bool,

        expandRow: PropTypes.object,

        className: PropTypes.string,
        containerClass: PropTypes.string,

        getRowStyle: PropTypes.func,

        hasPagination: PropTypes.bool,

        pageIndex: PropTypes.number,
        pageSize: PropTypes.number,
        totalRecords: PropTypes.number,

        onTableChange: PropTypes.func
    }

    static defaultProps = {
        data: [],
        columns: [],
        rowEvents: null,
        keyField: 'id',
        noDataText: NO_DATA_TEXT,

        isRemote: true,
        isStriped: false,
        isLoading: false,

        hasHover: true,
        hasHeader: false,
        hasBorders: false,

        getRowStyle: function () { return null },

        hasPagination: false,

        pageIndex: 1,
        pageSize: 25,
        totalRecords: 0,

        onTableChange: null
    }

    getRowStyle = (row, rowIndex) => {
        return this.props.getRowStyle(row, rowIndex)
    }

    render() {
        const {
            data,
            columns,
            rowEvents,
            keyField,
            expandRow,
            className,
            containerClass,
            isStriped,
            hasBorders,
            hasHover,
            noDataText,
            hasPagination,
        } = this.props

        const paginationOpts = {
            page: this.props.pageIndex,
            sizePerPage: this.props.pageSize,
            totalSize: this.props.totalRecords
        }

        return (
            <div className={cn('TableContainer', containerClass)}>
                <BootstrapTable
                    expandRow={expandRow}
                    data={data}
                    columns={columns}
                    rowEvents={rowEvents}
                    keyField={keyField}
                    classes={cn('Table', className)}
                    headerClasses={'Table-Header'}
                    striped={isStriped}
                    hover={hasHover}
                    bordered={hasBorders}
                    rowStyle={this.getRowStyle}
                    noDataIndication={noDataText}
                    pagination={hasPagination ? paginationFactory({ ...paginationOpts }) : null}
                    remote={{ pagination: hasPagination, filter: false, sort: false }}
                    loading={true}
                    //overlay={overlayFactory()}
                    onTableChange={this.props.onTableChange}
                />
            </div>
        )
    }
}
