import React, { useState } from 'react'
import DatePicker from '../DatePicker'
import SetField from '../Form/SetField';

import { FormGroup, Input, Button, Label, Row, Col } from 'reactstrap';

export default function Payment({ value, onCancel, onUpdate, onDelete }) {

    const fieldList = JSON.parse(value.attributes)

    const [editVisible, setEditVisible] = useState(false)
    const [editEnable, setEditEnable] = useState(false)

    const [receipt, setReceipt] = useState({
        number: value.number,
        date: value.date
    })

    const onVisibleClick = () => {
        setEditVisible(true);
        setTimeout(() => setEditEnable(true), 5000)
    }

    const onDetailUpdate = () => {
        onUpdate(value.uid, receipt.number, receipt.date);
    }

    const onDetailDelete = () => {
        if (window.confirm('Вы действительно хотите удалить этот платеж?'))
            onDelete(value.uid);
    }

    const onReceiptNumberChange = (e) => {
        setReceipt({
            ...receipt, number: e.target.value
        })
    }

    const onReceiptDateChange = (e) => {
        setReceipt({
            ...receipt, date: e
        })
    }

    return (
        <React.Fragment >
            <h3>{value.serviceName}</h3>
            <h5 className="lead">{value.providerName}</h5>
            <hr className="my-4" />
            <div>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label for="receiptNumber">Номер чека</Label>
                            <Input id="receiptNumber" placeholder="Введите номер чека" value={receipt.number} onChange={onReceiptNumberChange} />
                        </FormGroup></Col>
                    <Col>
                        <FormGroup>
                            <Label for="receiptDate">Дата чека</Label>
                            <DatePicker id="receiptDate" value={receipt.date} onChange={onReceiptDateChange} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col><SetField field={{ Name: "accountId", Title: "Лицевой счет", Value: value.accountId }} /></Col>
                    <Col><SetField field={{ Name: "cashierFio", Title: "ФИО кассира", Value: value.cashierFio }} /></Col>
                </Row>
                <Row>
                    <Col><SetField field={{ Name: "amount", Title: "Сумма", Value: value.amount.toFixed(2) }} /></Col>
                    <Col><SetField field={{ Name: "feeOut", Title: "Комиссия", Value: value.feeOut.toFixed(2) }} /></Col>
                    <Col><SetField field={{ Name: "total", Title: "Всего", Value: value.total.toFixed(2) }} /></Col>
                </Row>
                {fieldList
                    ? fieldList.reduce((result, field) => {
                        if (field.name === "id1") {
                            result.push(<SetField key={field.name} field={field} />);
                        }
                        return result;
                    }, [])
                    : 'Форма не имеет полей'}
            </div >
            <hr className="my-4" />
            <div className="d-flex mb-4" >
                {editVisible ?
                    <div>
                        <Button color="warning" className="mr-3" onClick={onDetailUpdate} disabled={!(fieldList && editEnable)}>Изменить</Button>
                        <Button color="danger" onClick={onDetailDelete} disabled={!(fieldList && editEnable)}>Удалить</Button>
                    </div> :
                    <button className="btn btn-link mr-3" onClick={onVisibleClick} disabled={!fieldList}>Редактировать</button>}
                <Button color="primary" className="d-flex ml-auto" onClick={onCancel}>Отмена</Button>
            </div>
            <hr className="my-4" />

        </React.Fragment >
    )
}
