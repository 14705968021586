import React from 'react'
import { FormGroup, Label, Input } from 'reactstrap';

export default function SetField({ field }) {
    const {
        Name,
        Title,
        Value } = field;

    return (
        <FormGroup>
            <Label for={Name}>{Title}</Label>
            <Input id={Name} value={Value} disabled />
        </FormGroup>)
}