import React, { Component, Fragment } from 'react';
import { NavItem, NavLink, Dropdown, DropdownItem, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Link } from 'react-router-dom';
import authService from './AuthorizeService';
import { ApplicationPaths } from './ApiAuthorizationConstants';
import userService from '../../services/UserService'

export class LoginMenu extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isAuthenticated: false,
            userName: null,
            userFullName: null,
            dropDownOpen: false
        };
    }

    componentDidMount() {
        this._subscription = authService.subscribe(() => this.populateState());
        this.populateState();
    }

    componentWillUnmount() {
        authService.unsubscribe(this._subscription);
    }

    async populateState() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])

        let userFullName = null;
        if (isAuthenticated)
        {
            const userInfo = await userService.getCurrentUser();
            userFullName = userInfo && userInfo.data && userInfo.data.fullName;
        }

        this.setState({
            ...this.state,
            isAuthenticated,
            userName: user && user.name,
            userFullName
        });
    }

    render() {
        const { isAuthenticated, userFullName, dropDownOpen } = this.state;
        if (!isAuthenticated) {
            //const registerPath = `${ApplicationPaths.Register}`;
            const loginPath = `${ApplicationPaths.Login}`;
            return this.anonymousView(/*registerPath,*/ loginPath);
        } else {
            //const profilePath = `${ApplicationPaths.Profile}`;
            const logoutPath = { pathname: `${ApplicationPaths.LogOut}`, state: { local: true } };
            return this.authenticatedView(/*userName, profilePath,*/dropDownOpen, userFullName, logoutPath);
        }
    }

    authenticatedView(/*userName, profilePath, */dropDownOpen, userFullName, logoutPath) {

        const toggle = () => {
            console.log(this.state);
            this.setState({
                ...this.state,
                dropDownOpen: !dropDownOpen
            });
        }

        return (<Fragment>
            {/*<NavItem>
                <NavLink tag={Link} className="text-dark" to={profilePath}>Hello {userName}</NavLink>
            </NavItem>*/}
            <Dropdown nav isOpen={dropDownOpen} toggle={toggle}>
                <DropdownToggle nav caret className="text-dark" >
                    {userFullName}
                </DropdownToggle>
                <DropdownMenu>
                    <DropdownItem header>Профиль пользователя</DropdownItem>
                    {/*<DropdownItem disabled>Action</DropdownItem>*/}
                    <DropdownItem tag={Link} className="text-dark" to={logoutPath}>Выйти</DropdownItem>
                    {/*<DropdownItem divider />
                    <DropdownItem>Another Action</DropdownItem>*/}
                </DropdownMenu>
            </Dropdown>
        </Fragment>);

    }

    anonymousView(/*registerPath, */loginPath) {
        return (<Fragment>
            {/*<NavItem>
                <NavLink tag={Link} className="text-dark" to={registerPath}>Register</NavLink>
            </NavItem>*/}
            <NavItem>
                <NavLink tag={Link} className="text-dark" to={loginPath}>Войти</NavLink>
            </NavItem>
        </Fragment>);
    }
}
