import React from 'react'
import SetField from '../Form/SetField'
import { FormGroup, Label, Button } from 'reactstrap'


export default function FindResult({ serviceInfo, prev, next, isWaiting }) {
    const service = {
        fieldList: [],
        addInfo: '',
        ...serviceInfo
    }

    return (<React.Fragment >
        <h5 className="lead">Информация по услуге</h5>
        {
            service.fieldList ?
                service.fieldList.map((field, index) =>
                    <SetField key={index} field={field} />
                ) : ''
        }
        {
            service.addInfo ?
                <FormGroup>
                    <Label for='addInfo'>Дополнительная информация</Label>
                    <div className="form-control" style={{ height: 'auto', backgroundColor: '#e9ecef' }} disabled>
                        {service.addInfo}
                    </div>
                </FormGroup> : ''
        }
        <hr className="my-4" />

        {isWaiting
            ? <p className="float-right mb-4">Переход к оплате...</p>
            : <div className="d-flex mb-4" >
                <Button color="primary" onClick={prev}>Назад</Button>
                <Button color="success" className="d-flex ml-auto" onClick={next}>Оплатить</Button>
            </div>
        }
    </React.Fragment>)
}
