import React from 'react'
import TextField from './TextField';
import SetField from './SetField';
//import InfoField from './InfoField';
import TextSelector from './TextSelector';

export default function FormFieldFactory({ field }) {

    return (<React.Fragment >
        {field.FieldType === 'TextField' ?
            <TextField field={field} /> :
            field.FieldType === 'SetField' ?
                <SetField field={field} /> :
                field.FieldType === 'InfoField' ?
                    /*<InfoField field={field} />*/'' :
                    field.FieldType === 'TextSelector' ?
                        <TextSelector field={field} /> : 'Элемент формы не распознан'}
    </React.Fragment >)
}