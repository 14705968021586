import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Catalog } from './components/Catalog/Catalog';
import Service from './components/Service/Service';
import CashBook from './components/CashBook/CashBook';
import Payment from './components/Payment/Payment';
import AuthorizeRoute from './components/api-authorization/AuthorizeRoute';
import ApiAuthorizationRoutes from './components/api-authorization/ApiAuthorizationRoutes';
import { ApplicationPaths } from './components/api-authorization/ApiAuthorizationConstants';

import './custom.css'

export default class App extends Component {
    static displayName = App.name;

    render() {
        return (
            <Layout>
                <Route exact path='/service/:servId' component={Service} />
                <Route exact path='/cash-book' component={CashBook} />
                <Route exact path='/payment' component={Payment} />
                <Route exact path={['/', '/catalog/:folderId?']} component={Catalog} />
                <Route path={ApplicationPaths.ApiAuthorizationPrefix} component={ApiAuthorizationRoutes} />
            </Layout>
        );
    }
}
