import React, { useState } from 'react'
import FormFieldFactory from './FormFieldFactory'

import { Button } from 'reactstrap';

export default function Form({ fieldList, next, isWaiting }) {

    const [, setFormValid] = useState(false);

    const onClick = () => {
        let isValid = true;
        fieldList.forEach((field) => {
            if (field?.Verify?.Regex === undefined) return;
            const regex = new RegExp(field.Verify.Regex);
            field.IsInvalid = !regex.test(field.Value ?? '');
            isValid = isValid && !field.IsInvalid;
        });

        setFormValid(Date.now());
        if (isValid) next();
    }

    return (
        <React.Fragment >
            <div>
                {fieldList
                    ? fieldList.map((field, index) =>
                        <FormFieldFactory key={index} field={field} />)
                    : 'Форма не имеет полей'}
            </div >
            <hr className="my-4" />
            {isWaiting
                ? <p className="float-right mb-4">Проверка услуги...</p>
                : <Button color="primary" className="float-right mb-4" onClick={onClick} disabled={!fieldList}>Далее</Button>
            }
        </React.Fragment >
    )
}
