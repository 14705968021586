import React, { useEffect, useState } from 'react'
import { Button, FormGroup, Label, Input } from 'reactstrap'
import DatePicker from '../DatePicker'

import Table from '../Table'
import Loader from '../Loader/Loader'

import Payment from '../Payment/Payment'

import service from '../../services/CashBookService'
import paymentService from '../../services/PaymentService'

const TITLE = 'Кассовая книга'

export default function CashBook(props) {

    // ДТО для страницы. Содержит страницу кассовой книги и итоги выборки всех записей 
    // удовлетворяющих условиям filter в целом
    const [cashBook, setCashBook] = useState({
        totalAmount: null,
        totalFeeOut: null,
        totalOverall: null,
        pageCashBook: null
    });

    // Текущий вид страницы (Таблица/Платеж)
    const [currentView, setCurrentView] = useState({ state: cashBookViews.Table, selectedRow: null });

    // Индикатор запроса данных с сервера
    const [isLoading, setIsLoading] = useState(true);

    // Список ФИО кассиров
    const [cashierList, setCashierList] = useState([]);

    // Список услуг
    const [serviceList, setServiceList] = useState([]);

    // Фильтр содержит параметры поиска записей для вывода на странице кассовой книги
    const [filter, setFilter] = useState({
        pageIndex: 1,
        pageSize: 25,
        serviceId: '',
        cashierFio: '',
        startDate: new Date().toISOString(),
        finishDate: new Date().toISOString()
    });

    // Срабатывает при изменении кассира в интерфейсе
    const onChangeCashier = (e) => {
        setFilter({ ...filter, cashierFio: e.target.value, pageIndex: 1 });
    }

    // Срабатывает при изменении услуги в интерфейсе
    const onChangeService = (e) => {
        setFilter({ ...filter, serviceId: e.target.value, pageIndex: 1 });
    }

    // Срабатывает при изменении начальной даты периода в интерфейсе
    const onStartDateChange = (value) => {
        setFilter({ ...filter, startDate: value, pageIndex: 1 });
    }

    // Срабатывает при изменении конечной даты периода в интерфейсе
    const onFinishDateChange = (value) => {
        setFilter({ ...filter, finishDate: value, pageIndex: 1 });
    }

    const onSearch = () => {

    }

    const onDetailCancel = () => {
        setCurrentView({ state: cashBookViews.Table, selectedRow: null })
    }

    const updateFiscalInfo = (uid, number, date) => {
        const fiscal = {
            paymentUid: uid,
            number: number,
            date: date
        };

        paymentService
            .updateFiscalInfo(fiscal)
            .then(
                (_) => {
                    if (filter.pageIndex === 1)
                        getCashBook();
                    else
                        setFilter({ ...filter, pageIndex: 1 });
                    onDetailCancel();
                },
                (err) => {
                    console.error(`paymentService.updateFiscalInfo: error:${err}`);
                }
            )
    }

    const removePayment = (uid) => {
        paymentService
            .remove(uid)
            .then(
                (_) => {
                    if (filter.pageIndex === 1)
                        getCashBook();
                    else
                        setFilter({ ...filter, pageIndex: 1 });
                    onDetailCancel();
                },
                (err) => {
                    console.error(`paymentService.remove: error:${err}`);
                }
            )
    }

    // Срабатывает в случае изменения пользователем в интерфейсе номера страницы или количества записей на странице
    // Обновляет filter что ведет к срабатыванию эффекта useEffect()
    const onTableChange = (type, newState) => {
        console.log('onTableChange');
        if (type === 'pagination') {
            setFilter({ ...filter, pageIndex: newState.page, pageSize: newState.sizePerPage });
        }
        console.log(type);
        console.log(newState);
    }

    const rowEvents = {
        onDoubleClick: (e, row, rowIndex) => {
            setCurrentView({ state: cashBookViews.Details, selectedRow: row })
        }
    }

    // Запрашивает с сервера страницу кассовой книги с параметрами filter
    const getCashBook = () => {
        let tune = {
            pageIndex: filter.pageIndex,
            pageSize: filter.pageSize,
            serviceId: filter.serviceId,
            cashierFio: filter.cashierFio,
        }
        if (filter.startDate != null)
            tune = { ...tune, startDate: filter.startDate }
        if (filter.finishDate != null)
            tune = { ...tune, finishDate: filter.finishDate }
        service.find(tune)
            .then(
                ({ success, data }) => {
                    if (success) {
                        setCashBook(data);
                    }
                    setIsLoading(false);
                },
                (err) => {
                    console.log(err);
                    setIsLoading(false);
                    setCashBook(null);
                })
    }

    // Запрашивает с сервера список услуг
    const getServices = () => {
        service.services().then(
            ({ success, data }) => {
                if (success) {
                    setServiceList(data);
                }
            },
            (err) => {
                console.log(err);
            })
    }

    // Запрашивает с сервера список кассиров
    const getCashiers = () => {
        service.cashiers().then(
            ({ success, data }) => {
                if (success) {
                    setCashierList(data);
                }
            },
            (err) => {
                console.log(err);
            })
    }

    // При инициализации компонента заполняет списки услуг и кассиров
    useEffect(() => getServices(), []);
    useEffect(() => getCashiers(), []);

    useEffect(() => setFilter({ ...filter, cashierFio: cashierList[1], pageIndex: 1 }), [cashierList]);
    useEffect(() => getCashBook(), [filter]);

    return (
        <React.Fragment>
            {currentView.state === cashBookViews.Details ?
                <Payment value={currentView.selectedRow} onCancel={onDetailCancel} onUpdate={updateFiscalInfo} onDelete={removePayment} /> :
                <React.Fragment>
                    <h3>{TITLE}</h3>
                    <div className="d-flex justify-content-start">
                        <FormGroup className="mr-4">
                            <Label for="Cashier">Начало периода</Label>
                            <DatePicker value={filter.startDate} onChange={onStartDateChange} />
                        </FormGroup>
                        <FormGroup className="mr-4">
                            <Label for="Cashier">Окончание периода</Label>
                            <DatePicker value={filter.finishDate} onChange={onFinishDateChange} />
                        </FormGroup>
                        <FormGroup className="mr-4">
                            <Label for="Cashier">Услуга</Label>
                            <Input type="select" id="Service" placeholder="Услуга" value={filter.serviceId} onChange={onChangeService}>
                                {serviceList.map((serv, index) => <option key={index} value={serv.serviceId}>{serv.serviceName}</option>)}
                            </Input>
                        </FormGroup>
                        <FormGroup>
                            <Label for="Cashier">Кассир</Label>
                            <Input type="select" id="Cashier" placeholder="ФИО кассира" value={filter.cashierFio} onChange={onChangeCashier}>
                                {cashierList.map((fio, index) => <option key={index} value={fio}>{fio}</option>)}
                            </Input>
                        </FormGroup>
                        <FormGroup className="d-flex align-items-end" style={{ display: "none" }}><Button color="primary" style={{ display: "none" }} className="px-5" onClick={onSearch}>Найти</Button></FormGroup>
                    </div>
                    {isLoading ? (<Loader />)
                        : cashBook && cashBook.pageCashBook && cashBook.pageCashBook.itemsCount > 0 ? (
                            <React.Fragment>
                                <Table
                                    data={cashBook.pageCashBook.items}
                                    hasPagination={true}
                                    pageIndex={cashBook.pageCashBook.pageIndex}
                                    pageSize={cashBook.pageCashBook.pageSize}
                                    totalRecords={cashBook.pageCashBook.totalRecords}
                                    onTableChange={onTableChange}
                                    className='table-sm'
                                    rowEvents={rowEvents}
                                    columns={[
                                        {
                                            dataField: 'providerName',
                                            text: 'Поставщик',
                                        },
                                        {
                                            dataField: 'serviceName',
                                            text: 'Услуга',
                                        },
                                        {
                                            dataField: 'accountId',
                                            text: 'Лицевой счет',
                                        },
                                        {
                                            dataField: 'number',
                                            align: 'right',
                                            text: '№ чека',
                                            footer: () => "Найдено записей " + cashBook.pageCashBook.totalRecords,
                                            footerAlign: "right",
                                            footerClasses: "text-muted",
                                            footerAttrs: { colSpan: 4 }
                                        },
                                        {
                                            dataField: 'date',
                                            align: 'right',
                                            text: 'Дата чека',
                                            formatter: (cell, row, rowIndex) => new Date(cell).toLocaleString()
                                        },
                                        {
                                            dataField: 'cashierFio',
                                            text: 'Кассир',
                                            footer: "Итого",
                                            footerAlign: "right",
                                            footerClasses: "text-muted",
                                            footerAttrs: { colSpan: 2 }
                                        },
                                        {
                                            dataField: 'amount',
                                            align: 'right',
                                            text: 'Сумма',
                                            formatter: (cell, row, rowIndex) => cell.toFixed(2),
                                            footer: () => cashBook.totalAmount.toFixed(2), // checkList.items.reduce((prev, cur) => prev + cur.amount, 0).toFixed(2),
                                            footerAlign: "right",
                                            footerClasses: "text-muted",
                                        },
                                        {
                                            dataField: 'feeOut',
                                            align: 'right',
                                            text: 'Комис.',
                                            formatter: (cell, row, rowIndex) => cell.toFixed(2),
                                            footer: () => cashBook.totalFeeOut.toFixed(2), // checkList.items.reduce((prev, cur) => prev + cur.feeOut, 0).toFixed(2),
                                            footerAlign: "right",
                                            footerClasses: "text-muted",
                                        },
                                        {
                                            dataField: 'total',
                                            align: 'right',
                                            text: 'Всего',
                                            formatter: (cell, row, rowIndex) => cell.toFixed(2),
                                            footer: () => cashBook.totalOverall.toFixed(2), // checkList.items.reduce((prev, cur) => prev + cur.total, 0).toFixed(2),
                                            footerAlign: "right",
                                            footerClasses: "text-muted",
                                        },
                                    ]}
                                />
                            </React.Fragment>
                        ) : ('Нет данных')
                    }
                </React.Fragment>
            }
        </React.Fragment>
    )
}

const cashBookViews = Object.freeze({ Table: 1, Details: 2 })
