import React, { Component } from 'react';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import Table from '../Table'
//import Header from '../Header/Header'
import Loader from '../Loader/Loader'

//import { ReactComponent as Appointment } from '../../images/appointment.svg'

import service from '../../services/CatalogService'

const TITLE = 'Каталог услуг'

export class Catalog extends Component {
    state = {
        folderId: this.props.match.params.folderId,
        data: null,
        isLoading: false,
    }

    constructor(props) {// установили обработчик - функцию this.handlePop, на событие popstate
        super(props);
        window.addEventListener('popstate', this.handlePop)
    }

    componentWillUnmount() {// сняли обработчик с события popstate
        window.removeEventListener('popstate', this.handlePop)
    }

    handlePop = () => { // создали новый метод, в котором вызываем forceUpdate. Этот метод мы установили в качестве обработчика выше
        const foldId = this.props.match.params.folderId
        this.load(foldId);
        this.forceUpdate();
    }

    componentDidMount() {
        this.load(this.state.folderId)
    }

    load(foldId) {
        this.setState({ isLoading: true })

        service
            .findByFolderId(foldId)
            .then(({ success, data }) => {
                if (success) {
                    this.setState({
                        data, isLoading: false, folderId: foldId
                    })
                }
            },
                ({ error, message }) => {
                    console.error(`CatalogService.findByFolderId: error:${error}, message:${message}`);
                    this.setState({
                        data: null, isLoading: false, folderId: foldId
                    })
                })
    }

    render() {
        const {
            data,
            isLoading,
        } = this.state

        const rowEvents = {
            onDoubleClick: (e, row, rowIndex) => {
                if (row.isFolder) {
                    const foldId = row?.id ?? null;
                    //this.setState({ folderId: foldId });
                    this.load(foldId);
                    this.props.history.push('/catalog' + (foldId ? `/${foldId}` : ''));
                    this.forceUpdate();
                }
                else {
                    // Переходим в форму услуги
                    this.props.history.push(`/service/${row.id}`);
                }
            }
        };

        const breadCrumbs = () => {

            const breadCrumbClick = (e, id) => {
                e.preventDefault();
                this.load(id)
            };

            const breadCrumbList = this.state.data.breadCrumbList;

            const currentFolderId = breadCrumbList[breadCrumbList.length - 1].id;

            return (<Breadcrumb>
                {breadCrumbList.map((item, i) =>
                    <React.Fragment key={i} >
                        {currentFolderId === item.id
                            ? <BreadcrumbItem active>{item.name}</BreadcrumbItem>
                            : <BreadcrumbItem><a href="/" onClick={(e) => breadCrumbClick(e, item.id)}>{item.name} </a></BreadcrumbItem>
                        }
                    </React.Fragment>)}
            </Breadcrumb>);
        }


        return (
            <React.Fragment>
                <h3>{TITLE}</h3>
                {data && data.breadCrumbList && breadCrumbs()}
                <div>
                    {isLoading ? (
                        <Loader />
                    ) : data && data.serviceList ? (
                        <div>
                            <div className="row">
                                <div className="col-lg-4 col-12">
                                    <Table
                                        data={data.serviceList}
                                        className='table-sm'
                                        rowEvents={rowEvents}
                                        columns={[
                                            {
                                                dataField: '#',
                                                text: '#',
                                                align: 'right',
                                                headerClasses: 'right',
                                                formatter: (cell, row, rowIndex) => {
                                                    return (
                                                        ++rowIndex
                                                    )
                                                }
                                            },
                                            {
                                                dataField: 'name',
                                                text: 'Наименование услуги',
                                                headerStyle: {
                                                    width: '100%'
                                                },
                                                formatter: (cell, row) => {
                                                    return (
                                                        <React.Fragment>{row.isFolder
                                                            ? <i className="fas fa-folder"></i>
                                                            : <i className="fas fa-square"></i>}
                                                            {cell}<br /><small>{row.serviceProviderName}</small>
                                                        </React.Fragment>
                                                    )
                                                }
                                            },
                                        ]}
                                    />
                                </div>
                                <div className="d-none d-lg-inline col-lg-8">
                                    <div className="row">
                                        <div className="col-4 thumb mb-4" onClick={() => this.props.history.push(`/service/71`)}>
                                            <img className="img-fluid img-thumbnail" src="./assets/beeline.png" alt="" />
                                        </div>
                                        <div className="col-4 thumb mb-4" onClick={() => this.props.history.push(`/service/227`)}>
                                            <img className="img-fluid img-thumbnail" src="./assets/mts.png" alt="" />
                                        </div>
                                        <div className="col-4 thumb mb-4" onClick={() => this.props.history.push(`/service/3926`)}>
                                            <img className="img-fluid img-thumbnail" src="./assets/megafon.png" alt="" />
                                        </div>
                                        <div className="col-4 thumb mb-4" onClick={() => this.props.history.push(`/service/3485`)}>
                                            <img className="img-fluid img-thumbnail" src="./assets/rostelecom.png" alt="" />
                                        </div>
                                        <div className="col-4 thumb mb-4" onClick={() => this.props.history.push(`/service/3263`)}>
                                            <img className="img-fluid img-thumbnail" src="./assets/bankcard.png" alt="" />
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    ) : 'Нет данных'}
                </div >
            </React.Fragment>
        )
    }
}