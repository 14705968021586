import BaseService from './BaseService'

export class ProcessingService extends BaseService {

    async checkAvailable(serviceId, accountId) {
        return super.request({
            uri: '/CheckAvailable',
            params: { serviceId, accountId },
            headers: await super.getAuth()
        })
    }
}

export default new ProcessingService()