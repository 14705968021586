import BaseService from './BaseService'

export class CatalogService extends BaseService {

    async find({ filter }) {
        return super.request({
            uri: '/catalog',
            params: { ...filter },
            headers: await super.getAuth()
        })
    }

    async findByFolderId(folderId) {
        return super.request({
            uri: `/catalog` + (folderId ? `/${folderId}` : ''),
            headers: await super.getAuth()
        })
    }

    /*
        findById (appointmentId) {
            return super.request({
                url: `/catalog/${goodId}`
            })
        }
    
        count () {
            return super.request({
                url: '/catalog/count'
            })
        }
    */
}

export default new CatalogService()