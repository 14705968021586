import React, { useState } from 'react'
import { FormGroup, Label, Input } from 'reactstrap';
import ReactHtmlParser from 'react-html-parser';

export default function TextField({ field }) {

    const {
        Name,
        Title,
        Message,
        Help,
        IsInvalid
    } = field;

    const [value, setValue] = useState(field.Value ?? '');

    const onChange = (event) => {
        setValue(event.target.value);
        field.Value = event.target.value;
    }

    return (
        <FormGroup>
            <Label for={Name}>{Title}</Label>
            <Input id={Name} placeholder={Message} value={value} onChange={onChange} className={IsInvalid ? 'is-invalid' : ''} />
            {IsInvalid ? ReactHtmlParser('<small class="form-text text-muted">' + Help + '</small>') : ''}
        </FormGroup>)
}