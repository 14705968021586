import BaseService from './BaseService'

export class PaymentService extends BaseService {

    async find(paymentId) {
        return super.request({
            uri: `/payment` + (paymentId ? `/${paymentId}` : ''),
            headers: await super.getAuth()
        })
    }

    async add(payment) {
        return super.request({
            uri: '/payment',
            method: 'POST',
            type: 'json',
            body: payment,
            headers: await super.getAuth()
        })
    }

    async updateFiscalInfo(fiscal) {
        return super.request({
            uri: '/payment',
            method: 'PUT',
            type: 'json',
            body: fiscal,
            headers: await super.getAuth()
        })
    }

    async remove(uid) {
        return super.request({
            uri: `/payment` + (uid ? `/${uid}` : ''),
            method: 'DELETE',
            type: 'json',
            headers: await super.getAuth()
        })
    }
}

export default new PaymentService()