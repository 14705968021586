import React, { useState } from 'react'
import { FormGroup, Label, Input } from 'reactstrap';

import FormFieldFactory from './FormFieldFactory';

export default function TextSelector({ field }) {

    const {
        Name,
        Title,
        Message,
        Decor,
        ItemList } = field;

    const [currentIndex, setCurrentIndex] = useState(0);
    const [value, setValue] = useState(field.Value ?? '');

    const onChange = (e) => {
        field.Value = e.target.value;
        setValue(e.target.value);
        setCurrentIndex(e.target.selectedIndex)
    }

    const ItemFields = (fieldList, decor) => {

        const itemFields = (fields) =>
            <React.Fragment >
                {fields.map((fld, i) => <FormFieldFactory key={i} field={fld} />)}
            </React.Fragment >

        const ordered2Columns = (items) => {
            const
                col1 = [],
                col2 = [];

            items.forEach((fld, i) => {
                if (i % 2 === 0) col1.push(fld);
                else col2.push(fld);
            })
            return (itemFields(col1.concat(col2)));
        }

        if (fieldList)
            return decor === '2column'
                ? <div style={{ columns: '2 auto' }}>
                    {ordered2Columns(fieldList)}
                </div>
                : itemFields(fieldList);
        else
            return '';
    }

    return (
        <React.Fragment >
            <FormGroup>
                <Label for={Name}>{Title}</Label>
                <Input type="select" id={Name} placeholder={Message} value={value} onChange={onChange}>
                    {ItemList.map((field, index) => <option key={index} value={field.Value}>{field.Text}</option>)}
                </Input>
            </FormGroup>
            {ItemFields(ItemList[currentIndex].ItemFieldList, Decor)}
        </React.Fragment >)
}

TextSelector.defaultProps = {
    ItemList: []
}
