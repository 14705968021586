import React from 'react'
import { Button } from 'reactstrap';
import { useHistory } from "react-router-dom";


export default function Complete({ summary }) {

    let history = useHistory();

    const onClickCatalog = () => {
        history.push("/");
    }

    return (
        <div className="jumbotron">
            <h1 className="display-4 text-success">Оплата прошла успешно</h1>
            <p className="lead">
                Лицевой счет: {summary.AccountId}<br />
                Сумма оплаты: {summary.Amount.toFixed(2)}<br />
                Комиссия: {summary.FeeOut}<br />
                Итого: {(summary.Amount + summary.FeeOut).toFixed(2)}<br />
            </p>
            <p className="lead">
                Кассовый чек: № {summary.number} от {new Date(summary.date).toLocaleDateString()}
            </p>
            <hr className="my-4" />
            <div className="d-flex mb-4" >
                <Button color="primary" className="d-flex ml-auto" onClick={onClickCatalog}>Вернуться в каталог</Button>
            </div>
            {/*<React.Fragment >
            <p>Оплата прошла успешно.</p>
            <p>Сумма оплаты: [СУММА]</p>
            <p>Комиссия: [комиссия]</p>
            <p>Итого: [комиссия]</p>
            <p><br /><br />Кассовый чек №: [НОМЕР] от [ДАТА]</p>
            <div className="d-flex mb-4" >
                <Button color="primary" onClick={prev}>Справочный документ</Button>
                <Button color="success" className="d-flex ml-auto" onClick={next}>Вернуться в каталог</Button>
            </div>
        </React.Fragment >*/}
        </div >
    )
}
