import React, { useState, useEffect } from 'react'
import { Button, FormGroup, Label, Input } from 'reactstrap';

export default function Calculate({ amounts, prev, next, isWaiting }) {

    const [payment, setPayment] = useState({
        amount: amounts.amount ?? '',
        amountFee: amounts.amountFee ?? '',
        total: amounts.total ?? ''
    });

    const onChangeAmount = (e) => {
        let amount = e.target.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');

        let point = '';
        if (amount.endsWith('.')) {
            point = '.';
            amount = amount.substring(0, amount.length - 1);
        }
        if (amount === '') amount = '0';

        let value = parseFloat(amount);
        value = Math.round((value + 10e-7) * 100) / 100;
        amount = value.toString() + point;

        if (value < amounts.MinSum ?? 0) value = amounts.MinSum;
        else if ((amounts.MaxSum ?? 0) > 0 && value > (amounts.MaxSum ?? 0)) value = amounts.MaxSum;

        let fee = (amounts.feeOutFixed ?? 0) > 0 ? amounts.feeOutFixed : (value * amounts.feeOutPercent / 100);
        if (fee < amounts.feeOutMin ?? 0) fee = amounts.feeOutMin;
        else if ((amounts.feeOutMax ?? 0) > 0 && fee > (amounts.feeOutMax ?? 0)) fee = amounts.feeOutMax;
        fee = Math.round((fee + 10e-7) * 100) / 100;

        let total = Math.round((value + fee + 10e-7) * 100) / 100;

        setPayment({
            amount: amount,
            amountFee: fee,
            total: total
        });
    }

    const onBlur = () => {
        amounts.amount = parseFloat(payment.amount);
        amounts.amountFee = parseFloat(payment.amountFee);
        amounts.total = parseFloat(payment.total);
    }

    const onNextClick = (e) => {
        onBlur();
        next(e);
    }

    useEffect(() => {
        const e = {
            target: { value: (amounts.amount ?? 0).toString() }
        }
        onChangeAmount(e);
    }, [])

    return (
        <React.Fragment >
            <FormGroup>
                <Label for="Amount">Сумма платежа</Label>
                <Input id="Amount" value={payment.amount} onChange={onChangeAmount} onBlur={onBlur} placeholder="Введите сумму платежа" />
            </FormGroup>
            <FormGroup>
                <Label for="AmountFee">Комиссия ({(amounts.feeOutFixed ?? 0) > 0 ? amounts.feeOutFixed + ' руб.' : amounts.feeOutPercent + '%' + (amounts.feeOutMin === null ? '' : ' мин.: ' + amounts.feeOutMin + ' руб.') + (amounts.feeOutMax === null ? '' : ' макс.: ' + amounts.feeOutMax + ' руб.')})</Label>
                <Input id="AmountFee" value={payment.amountFee} disabled />
            </FormGroup>
            <FormGroup>
                <Label for="Total">Итого к оплате</Label>
                <Input id="Total" value={payment.total} disabled />
            </FormGroup>
            <small className="form-text text-muted">
                Минимальная сумма: {amounts.minSum}
                <br />
                Максимальная сумма: {amounts.maxSum}
            </small>
            <hr className="my-4" />
            {isWaiting
                ? <p className="float-right mb-4">Оплата услуги...</p>
                : <div className="d-flex mb-4" >
                    <Button color="primary" onClick={prev}>Назад</Button>
                    <Button color="success" className="d-flex ml-auto" onClick={onNextClick}>Оплатить</Button>
                </div>}
        </React.Fragment >
    )
}
