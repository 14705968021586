import React, { Component } from 'react'
import DatePicker from 'reactstrap-date-picker'
import PropTypes from 'prop-types'

export default class Table extends Component {

    static propTypes = {
        dateFormat: PropTypes.string,
        weekStartsOn: PropTypes.number,
        showClearButton: PropTypes.bool,
        dayLabels: PropTypes.arrayOf(PropTypes.string),
        monthLabels: PropTypes.arrayOf(PropTypes.string),
        showTodayButton: PropTypes.bool,
        todayButtonLabel: PropTypes.string,
        defaultValue: PropTypes.string,
        value: PropTypes.string,
        onChange: PropTypes.func
    }

    static defaultProps = {
        dateFormat: "DD.MM.YYYY",
        weekStartsOn: 1,
        showClearButton: false,
        dayLabels: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
        monthLabels: ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'],
        showTodayButton: false,
        todayButtonLabel: "Сегодня",
        //defaultValue: new Date().toISOString()
    }

    render() {
        const {
            dateFormat,
            weekStartsOn,
            showClearButton,
            dayLabels,
            monthLabels,
            showTodayButton,
            todayButtonLabel,
            defaultValue,
            value,
            onChange
        } = this.props

        return (< DatePicker dateFormat={dateFormat} weekStartsOn={weekStartsOn} showClearButton={showClearButton} dayLabels={dayLabels} monthLabels={monthLabels} showTodayButton={showTodayButton} todayButtonLabel={todayButtonLabel} defaultValue={defaultValue} value={value} onChange={onChange} />)
    }
}